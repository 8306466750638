import "../styles.css"

function Header() {
    return (
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div class="container px-4 px-lg-5">
                <a class="navbar-brand fw-bold" href="/">ChatCGT</a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item"><a class="nav-link fw-bold" href="/about">About us</a></li>
                        <li class="nav-item"><a class="nav-link fw-bold" href="/services">Our Services</a></li>
                        <li class="nav-item"><a class="nav-link fw-bold" href="/info">CGT Info</a></li>
                        <li class="nav-item"><a class="nav-link fw-bold" href="/agents">Agents/Solicitors</a></li>
                        <li class="nav-item"><a class="nav-link fw-bold" href="/faqs">FAQ's</a></li>
                        <li class="nav-item"><a class="nav-link fw-bold" href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            </nav>

    );
}

export default Header;