function Home() {
  return (
    <>
      <header class="masthead">
        <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div class="d-flex justify-content-center w-100">
            <div class="text-center justify-content-center w-100">
              <h1 class="mx-auto my-0 text-uppercase w-100">ChatCGT</h1>
              <h2 class="text-white mx-auto w-100 mt-2 mb-5 fw-bold">
                Simplifying your{" "}
                <b style={{ fontSize: "bigger" }}>Capital Gains Tax</b> file &
                pay process.
              </h2>
              <div class="d-flex justify-content-center">
                <a
                  class="btn btn-primary my-auto"
                  style={{ margin: "2vw" }}
                  href="/services"
                >
                  Our services
                </a>
                <a
                  class="btn btn-primary my-auto"
                  style={{ margin: "2vw" }}
                  href="https://78hcfle3cl8.typeform.com/to/rUw4Egui"
                  target="_blank"
                >
                  Check my CGT
                </a>
              </div>
              <h2 class="mx-auto my-0">
                <a
                  style={{ display: "block", padding: "2vh" }}
                  href="#projects"
                >
                  Click To learn more about CGT!
                </a>
              </h2>
            </div>
          </div>
        </div>
      </header>
      <section class="home-section bg-default" id="projects">
        <div class="container px-4 px-lg-5">
          <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
            <div class="col-xl-4 col-lg-5"></div>
            <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
              <div class="col-lg-6">
                <img
                  class="img-fluid"
                  src={require("./img/tax.jpg")}
                  alt="..."
                />
              </div>
              <div class="col-lg-6">
                <div class="bg-black blue-border text-center h-100 project">
                  <div class="d-flex h-100">
                    <div class="project-text w-100 my-auto text-center text-lg-left">
                      <h4 class="text-white fw-bold fst-italic">
                        How we help you:
                      </h4>
                      <p class="mb-0 text-white-50">
                        <br />
                        Our service streamlines the entire CGT file and pay
                        process for you from start to submission. We ensure you
                        don’t incur any penalties, fulfil your legal
                        obligations, and pay the correct amount of tax.
                      </p>
                      <br />
                      <p class="mb-0 text-white-50">
                        Read more <a href="">here</a> about our services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-0 justify-content-center">
              <div class="col-lg-6">
                <img
                  class="img-fluid"
                  style={{ height: "100%" }}
                  src={require("./img/hmrc.jpg")}
                  alt="..."
                />
              </div>
              <div class="col-lg-6 order-lg-first">
                <div class="bg-black blue-border text-center h-100 project">
                  <div class="d-flex h-100">
                    <div class="project-text w-100 my-auto text-center text-lg-right">
                      <h4 class="text-white fw-bold fst-italic">
                        HMRC requirements and penalties
                      </h4>
                      <p class="mb-0 text-white-50">
                        <br />
                        CGT taxes the profit made on any asset (e.g. property)
                        you’ve disposed of (e.g. sold), and HMRC has changed the
                        way you pay it. Now, you only have 60 days from the
                        point of sale to file & pay your CGT – making it
                        imperative that:
                        <br />
                        1. your tax is correctly calculated,
                        <br />
                        2. you know what tax reliefs to apply,
                        <br />
                        3. and your documents are accurate.
                        <br />
                        This is especially important as HMRC can charge
                        penalties for what they deem inaccurate and inconsistent
                        information between your file & pay submission and tax
                        return.
                        <br />
                        <br />
                        Read more{" "}
                        <a class="fw-bold " href="">
                          here
                        </a>{" "}
                        about HMRC's penalties.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
              <div class="col-xl-4 col-lg-5"></div>
              <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                <div class="col-lg-6">
                  <img
                    class="img-fluid"
                    src={require("./img/cgt-house.jpg")}
                    alt="..."
                  />
                </div>
                <div class="col-lg-6">
                  <div class="bg-black blue-border text-center h-100 project">
                    <div class="d-flex h-100">
                      <div class="project-text w-100 my-auto text-center text-lg-left">
                        <h4 class="text-white fw-bold fst-italic">
                          CGT Made Hassle-Free
                        </h4>
                        <p class="mb-0 text-white-50">
                          <br />
                          Have you recently sold an additional property? Then
                          you’ll need to calculate whether you owe Capital Gains
                          Tax (CGT) sooner than you think.
                        </p>
                        <p class="mb-0 text-white-50">
                          <br />
                          Read more <a href="">here</a> about our services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row gx-0 mb-4 mb-lg-5 align-items-center justify-content-center flex-column">
            <div class="text-center">
              <h2 class="text-grey-50 mx-auto mt-2 mb-5 fw-bold fst-italic">
                CLick here to see if you need to pay any CGT!
              </h2>
              <h3 class="text-grey-50 mx-auto mt-2 mb-5">
                We've simplified the process of working out whether you need to
                make a submission under file and pay. All for free!
              </h3>
              <a class="btn btn-primary" href="https://78hcfle3cl8.typeform.com/to/rUw4Egui" target="_blank">
                Check my CGT
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
