function Info() {
  return (
    <>
      <section class="about-section bg-default text-center" id="about">
        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-8">
              <h2 class="text-white mb-4 fw-bold text-decoration-underline">
                CGT INFO PAGE:
              </h2>
              <p class="text-white-50 fw-bold">
                We know learning about CGT isn’t the most exciting use of your
                time. That’s why we learn the ins and outs: so you don’t have
                to. <br />
                But, if you want to know more about CGT, here’s a more detailed
                overview:
              </p>
            </div>
          </div>
        </div>

        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 d-flex justify-content-center">
            <p class="text-white-50">
              When you dispose of an asset that’s increased in value, you may
              have to pay CGT (Capital Gains Tax) on the profit. From the 6 th
              April 2023, HMRC requires you to file &amp; pay your CGT within
              just 60 days. <br />
              “Disposing an asset” can mean various things, but we will be
              focusing on managing your CGT file &amp; pay process only if
              you’ve sold a property. <br /> If you haven’t sold a property and
              are unsure of what other circumstances would make you liable to
              pay CGT, find out on the government website here:{" "}
              <a href="https://www.gov.uk/capital-gains-tax">
                https://www.gov.uk/capital-gains-tax
              </a>
            </p>
            <h2 class="text-white mb-4 fw-bold">CGT RATES:</h2>
            <p class="text-white-50">
              One of the factors used in calculating CGT is your income.
              Currently, there are 5 rates that CGT is charged at depending on
              income and what asset is being disposed of. Since we are focusing
              on calculating CGT for you if you’ve sold a property, the 18 and
              28% are the most relevant rates:
              <br />
              <br />
              <ul>
                <li>
                
                  10% where business asset disposal relief is available
                </li>
                <li>
                  <b>18% on gains on residential property made by basic rate
                  taxpayers</b>
                </li>
                <li>
                  <b>28% on gains on residential property made by higher rate
                  taxpayers</b>
                  
                </li>
                <li> 10% on most other gains made by basic rate taxpayers</li>
                <li>20% on most other gains made by higher rate taxpayers</li>
              </ul>
              <br />
              It is also important to note that these rates only apply to the
              taxable gain, NOT the total gain. The Annual Exempt Amount allows
              a certain portion of your gain to be taken free of tax. For this
              2023/24 tax year, the Annual Exemption is £6,000. This will be
              reduced to £3,000 on 6 th April 2024.
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">
              HMRC’s Penalties:
            </h2>
            <p class="text-white-50">
              With only 60 days from the point of sale to file &amp; pay your
              CGT, you can never be too careful that all your documents and
              calculations are accurate to avoid penalties. <br /> There are currently
              3 types: late penalties, inaccuracy penalties and failure to
              notify penalties.
            </p>
            <h5 class="text-white fw-bold text-decoration-underline">LATE PENALTIES:</h5>
            <p class="text-white-50">
              If a return is filed MORE THAN 60 DAYS from the point of sale,
              there is a late penalty of £100.  If a return is filed MORE THAN
              6 MONTHS from the point of sale, there is a late penalty of £300
              OR 5% of the outstanding tax, whichever is higher.  If a return
              is filed MORE THAN 12 MONTHS from the point of sale, there is also
              a late penalty of £300 or 5% of the outstanding tax, whichever is
              higher.
            </p>
            <h5 class="text-white fw-bold text-decoration-underline">INACCURACY PENALTIES:</h5>
            <p class="text-white-50">
              If a penalty arises DUE TO LACK OF REASONABLE CARE, the penalty
              will be between 0 and 30% of the extra tax due.  If the ERROR IS
              DELIBERATE, the penalty will be between 20 and 70% of the extra
              tax due.  If the ERROR IS DELIBERATE AND CONCEALED, the penalty
              will be between 30 and 100% of the extra tax due.
            </p>
            <h5 class="text-white fw-bold text-decoration-underline">FAILURE TO NOTIFY PENALTY:</h5>
            <p class="text-white-50">
              If you do not inform HMRC when changes happen that affect your
              liability to tax, there could be a penalty. For example, selling
              an asset that’s made a capital gain and not filing &amp; paying it
              could result in a penalty.  This penalty is calculated based on
              the amount of tax that’s been unpaid as a result of the failure to
              notify.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Info;
