import "./styles.css";
import Header from "./components/header"
import Home from "./components/home";
import About from "./components/about";
import Typeform from "./components/typeform";
import Footer from "./components/footer";
import Faqs from "./components/faq";
import Info from "./components/info";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Services from "./components/services";
import Agents from "./components/agents";

function App() {
  
  return (
    <div>
      <Header />
      <Router>
        <Routes>

          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/form" element={<Typeform />}></Route>
          <Route path="/faqs" element={<Faqs />}></Route>
          <Route path="/info" element={<Info />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/agents" element={<Agents />}></Route>
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;


