function Footer() {
  return (
    <>
      <section class="contact-section bg-black" id="contact">
        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5">
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                  <h4 class="text-uppercase m-0">Address</h4>
                  <hr class="my-4 mx-auto" />
                  <div class="small text-black-50">
                    50 Humberstone road, LE5 OEG
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-envelope text-primary mb-2"></i>
                  <h4 class="text-uppercase m-0">Email</h4>
                  <hr class="my-4 mx-auto" />
                  <div class="small text-black-50">
                    <a href="#!">javeed@goweronline.co.uk</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="card py-4 h-100">
                <div class="card-body text-center">
                  <i class="fas fa-mobile-alt text-primary mb-2"></i>
                  <h4 class="text-uppercase m-0">Phone</h4>
                  <hr class="my-4 mx-auto" />
                  <div class="small text-black-50">07970415788</div>
                </div>
              </div>
            </div>
          </div>
          <div class="social d-flex justify-content-center">
            <a class="mx-2" href="https://accountantsleicester.net/" target="_blank">
              <i class="fa-solid fa-code"></i>
            </a>
            <a class="mx-2" href="https://www.tiktok.com/@inspiredaccounting" target="_blank">
              <i class="fab fa-tiktok"></i>
            </a>
            <a class="mx-2" href="#!" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </section>

      <footer class="footer bg-black small text-center text-white-50">
        <div class="container px-4 px-lg-5">
        &copy; 2023 Gower Accountancy. All rights reserved 
        </div>
      </footer>
    </>
  );
}

export default Footer;
