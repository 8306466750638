function Agents() {
  return (
    <>
      <section class="about-section bg-default text-center" id="about">
        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-8">
              <h2 class="text-white mb-4">Solicitors & Estate Agents</h2>
              <p class="text-white-50">
                This page is dedicated to Solicitors and Estate Agents that wish
                to register with us to refer your clients. <br />  <br />
                The unique service helps your client work through the maze of
                Capital Gains Tax on the sale of their property(s). <br /> <br />
                The welfare of our mutual client is always at the heart of the
                service we provide and because you are not in the position to
                provide tax advice our coming together forms a perfect union.
                <br /> <br />
                Register with us below to learn how we can help add value to the
                services you provide, support your client and be rewarded for
                each successful referral.
              </p>
              <div class="d-flex justify-content-center row-cols-2">
                <a
                  class="btn btn-primary my-auto btn-block"
                  style={{ margin: "2vw" }}
                  href="https://78hcfle3cl8.typeform.com/to/ZyUQ1PAB"
                  target="_blank"
                >
                  Refer a client
                </a>
                <a
                  class="btn btn-primary my-auto btn-block"
                  style={{ margin: "2vw" }}
                  href="https://78hcfle3cl8.typeform.com/to/OBR6HLm4"
                  target="_blank"
                >
                  Agent Registration
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Agents;
