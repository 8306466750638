import { Widget } from '@typeform/embed-react'

const Typeform = () => {
  
  return <>
    <section class="about-section bg-default text-center">
      <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-8">
            <h2 class="text-white mb-4">Question Form</h2>
            <p class="text-white-50">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium sed, blanditiis itaque consectetur eum quos maxime laboriosam error nulla repellendus in enim harum facilis libero sint, voluptatem reprehenderit! Consectetur corrupti, temporibus impedit dolore ducimus natus nam aperiam earum nemo architecto numquam modi ab ratione accusamus error officiis eius tempora? Sunt eaque sint rerum delectus similique laborum pariatur. Adipisci nulla dolorum ipsum unde quos aspernatur, consectetur dolores. Labore laborum aut accusantium a, officiis itaque neque adipisci praesentium in natus obcaecati ipsam voluptate temporibus quasi amet ullam? Repellat consequatur vitae non quos, ullam excepturi corporis voluptatem porro debitis facilis maxime perspiciatis deleniti!
            </p>
          </div>
        </div>
      </div>
      </section>
  <Widget id="ZyUQ1PAB" style={{ width: '100%', height: '100vh', overflowY: 'hidden'}} className="my-form justify-content-center"/>
  </>
}

export default Typeform;