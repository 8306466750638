function About() {
  return (
    <>
      <section class="about-section bg-default text-center" id="about">
        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-8">
              <h2 class="text-white mb-4">CGT MADE HASSLE-FREE.</h2>
              <p class="text-white-50">
                Our ACCA accredited accountants have dealt with CGT in its
                various forms throughout 2 decades. This means we have both the
                extensive knowledge and experience to ensure you’re never out of
                the loop when it comes to your CGT file & pay process.
                <br />
                <br />
                Our services are born out of HMRC’s relentless pursuit of tax,
                penalties and interest. As we understand that there is a legal
                requirement to complete CGT, we want to ensure people don’t
                suffer the high penalties and interest that they are looking to
                charge by imposing a 60-day limit.
                <br />
                <br />
                Whether you just need that extra reassurance to avoid incurring
                any penalties, or you want to pass on the headache to
                accountants who take pride in making CGT hassle-free, we will
                work with you to make the file & pay process as simple as
                possible.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
