function Services() {
  return (
    <>
      <section class="about-section bg-default text-center" id="about">
        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <h2 class="text-white mb-4 text-decoration-underline">
              Our Services:
            </h2>
            <p class="text-white-50">
              Our ACCA accredited accountants have formulated a 5-step process
              to simplify your CGT file & pay:
              <br />
              <br />
              1. Using our tailor-made online forms, we’ll collect the most
              relevant data to complete your submission in a quick and easy
              fashion.
              <br />
              2. We’ll work out if there any available reliefs to help reduce
              your tax.
              <br />
              3. We’ll calculate your Tax and relay this information back to you
              by email and a telephone call.
              <br />
              4. We’ll help you register with HMRC which is required to complete
              the online process.
              <br />
              5. And finally, we’ll complete the CGT submission on your behalf
              and tell you how to make payment. We do not handle any of your
              money for safety reasons.
              <br />
              <br />
              This will all be completed within your 60-day timeframe, with any
              urgent cases receiving urgent attention.{" "}
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">Pricing:</h2>
            <p class="text-white-50">
              Prices for our services are given when you complete a quick
              questionnaire, the final price will depend on the on complexity
              and number of submissions required.
              <br />
              <br />
              <u>
                <i>
                  <b>Please note:</b>
                </i>
              </u>{" "}
              We don’t share you information you provide us with anyone else;
              it’s protected and kept strictly confidential.
              <br />
              <br />
              This is a personal service, where you get to speak to real people
              and have your data managed by real professionals.
              <br />
              <br />
              We provide our services no matter how simple or complex and only
              make a submission to HMRC when you are happy with your
              calculations.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
export default Services;
