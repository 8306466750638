function Faqs() {
  return (
    <>
      <section class="about-section bg-default text-center" id="faqs">
        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-8">
              <h2 class="text-white mb-4">Frequently asked questions</h2>
              <p class="text-white-50">
                Welcome to our FAQ page dedicated to CGT, and our services.
                Here, we provide answers to some commonly asked questions
                regarding CGT and how our services help you to file &amp; pay
                your CGT. <br /><br /> If you have any questions and don’t find them answered
                here, please feel free to contact us at: (phone number,
                email…etc)
              </p>
            </div>
          </div>
        </div>

        <div class="container px-4 px-lg-5">
          <div class="row gx-4 gx-lg-5 justify-content-center">
            <h2 class="text-white mb-4 text-decoration-underline">
              What is Capital Gains Tax (CGT)?
            </h2>
            <p class="text-white-50">
              Capital Gains Tax is the tax HMRC charges on the profit made when
              you’ve “disposed” of an “asset” (e.g. sold a property).
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">
              If I am liable to pay CGT, when will I have to pay it?
            </h2>
            <p class="text-white-50">
              On the 6 th April 2020, when you sell a property, HMRC requires
              you to file &amp; pay your CGT within 60 days of having made the
              sale.
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">
              When do I not have to pay CGT?
            </h2>
            <p class="text-white-50">
              If the gain is fully covered by exemptions, such as the Annual
              Exemption (which is £6,000 for the 2023/24 tax year) or Private
              Residence Relief, then you will not have to pay tax. To check if
              you’re eligible for Private Residence Relief, you can visit the
              government website: <br></br>
              <a href="https://www.gov.uk/tax-relief-selling-home">
                https://www.gov.uk/tax-relief-selling-home
              </a>
              <br></br> If the disposal of property is between spouses or civil
              partners, then there is also usually no tax to pay. Additionally,
              if the property is being sold at a loss or with no gain, then
              there will be no CGT to file &amp; pay.
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">
              How can I find out whether I need to file &amp; pay CGT?
            </h2>
            <p class="text-white-50">
              We’ve simplified the process of working out whether you need to
              make a CGT file &amp; pay submission. Click <a href="/form">here</a> to take the free
              assessment.
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">
              I am thinking of selling a property. Can I get advice on how to
              reduce my CGT when I eventually sell?
            </h2>
            <p class="text-white-50">
              Currently, our services focus on calculating your CGT if you’ve
              already sold a property. However, we may introduce a paid
              consultation service to advise people on how to legally reduce
              their CGT liability if they’re selling properties. In such a case,
              it will be updated here and on the Our Services page.
            </p>
            <h2 class="text-white mb-4 text-decoration-underline">
              For the inaccuracy penalties, what does “lack of reasonable care”
              mean?
            </h2>
            <p class="text-white-50">
              “Reasonable care” depends on your circumstances, as every client
              is different. Some clients’ tax affairs are fairly straightforward
              whilst large businesses may have more complex tax affairs, and
              need to take more steps to ensure those affairs are in order. If
              HMRC does not deem you to have taken the reasonable steps to
              ensure your tax affairs are in order and something is inaccurate
              or inconsistent between your file &amp; pay and tax returns, then
              you may be charged with a penalty. <br />Taking reasonable steps can
              also include checking things you’re unsure of with an agent, or
              HMRC themselves.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faqs;
